import { useContext, useEffect } from "react";
import { MenuContext } from "../../../Provider/MenuProvider";
import styled from "styled-components";
import {
  ModalContent,
  WalletAddressInfo,
  WalletInfo,
  WalletInfoContent,
} from "../../../components/ModalDisConnect/styled";

const ModalConfirm = ({ handleConfirm, tabsActive, amount, address }: any) => {
  const { handleCloseCustom } = useContext(MenuContext);
  return (
    <ModalLayout>
      <ModalContainer
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <WalletInfoContent>
          <ModalContent>
            <h3>Your {tabsActive === 1 ? "Withdraw" : "Transfer"}</h3>
            <WalletInfo>
              <p>Amount:</p>
              <WalletAddressInfo>
                <p>{amount}</p>
              </WalletAddressInfo>
              {tabsActive === 2 &&
                <>
                  <p>To Address:</p>
                  <WalletAddressInfo>
                    <p>
                      {address?.slice(0, 4)}...{address?.slice(-4)}
                    </p>
                  </WalletAddressInfo>
                </>
              }
              <BtnGroup>
                <BtnCancel
                  onClick={() => {
                    handleCloseCustom();
                  }}
                >
                  <p>Cancel</p>
                </BtnCancel>
                <BtnCancel
                  onClick={() => {
                    handleConfirm();
                    handleCloseCustom();
                  }}
                >
                  <p>{tabsActive === 1 ? "Withdraw" : "Transfer"}</p>
                </BtnCancel>
              </BtnGroup>
            </WalletInfo>
          </ModalContent>
        </WalletInfoContent>
      </ModalContainer>
    </ModalLayout>
  );
};
export default ModalConfirm;
const ModalLayout = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
const ModalContainer = styled.div`
  border-radius: 20px;
  width: 100%;
  max-width: 420px;
`;
const BtnGroup = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
`;
const BtnCancel = styled.button`
  border-radius: 34px;
  background: var(--Gray-80, #444750);
  padding: 10px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(50% - 2px);
  border: none;
  cursor: pointer;
  p {
    line-height: 100%;
  }
  &:first-child {
    background: var(--Blue-Base, #ea2d2d);
  }
`;
const BtnClose = styled.div`
  background: var(--Gray-80, #444750);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: absolute;
  top: 12px;
  right: 12px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;
