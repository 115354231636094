import styled from "styled-components";
import Bg from "../../assets/Bonus/Bg.png";
import { TableContainer } from "../../components/Table";
import BgProgess from "../../assets/Bonus/progessBg.png";

export const BonusWapper = styled.div`
  width: 100%;
  padding: 40px 24px 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 1660px;
  margin: 0px auto;
  @media screen and (max-width: 767px) {
    padding: 24px 15px 110px;
    gap: 20px;
  }
`;
export const BonusBalanceBox = styled.div`
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
`;
export const BonusBalanceBoxTitle = styled.div`
  padding: 18px 16px;
  background: var(--Blue-Base, #0085ff);
  p {
    leading-trim: both;
    text-edge: cap;
    font-family: AnekLatin-Regular;
    font-weight: 500;
    font-size: 20px;
    line-height: 100%;
    font-weight: 500;
    line-height: normal;
    background: linear-gradient(90deg, #fff 51.48%, #80c2ff 171.59%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media screen and (max-width: 767px) {
    padding: 15px;
  }
`;
export const BonusBalanceBoxContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 36px 14px 14px;
  background-color: var(--Gray-90, #2d2f35);
  background-image: url(${Bg});
  background-position: center;
  background-repeat: no-repeat;
  h3 {
    color: #fff;
    font-family: "PlusJakartaSans";
    font-size: 32px;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: 0.48px;
    span {
      color: var(--Blue-Base, #0085ff);
    }
  }
  p {
    color: var(--Gray-Base, #878ea0);
    font-family: AnekLatin-Regular;
    font-weight: 500;
    font-size: 24px;
    font-weight: 400;
    line-height: 20px; /* 83.333% */
  }
  @media only screen and (max-width: 767px) {
    padding-top: 14px;
  }
`;

export const ProgessContent = styled.div<{ value?: any }>`
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  div {
    display: flex;
    align-items: center;
    align-self: stretch;
    &:first-child {
      gap: 8px;
      border-radius: 50px;
      background: rgba(255, 255, 255, 0.25);
      padding: 16px;
      width: fit-content;
      p {
        color: #fff;
        font-family: AnekLatin-Regular;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 19.2px */
      }
    }
    &:last-child {
      display: flex;
      width: 100%;
      flex-direction: column;
      max-width: 337px;
      background-image: url(${BgProgess});
      background-size: cover;
      border-radius: 50px;
      position: relative;
      overflow: hidden;
      justify-content: center;
      &::before {
        content: "";
        width: ${({ value }) => value}%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: #0085ff;
        border-radius: 4px;
      }
      p {
        color: #fff;
        font-family: AnekLatin-Regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 19.2px */
        position: relative;
        z-index: 2;
      }
      h6 {
        color: #fff;
        text-align: center;
        font-family: "PlusJakartaSans";
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 18px */
        position: relative;
        z-index: 2;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    gap: 10px;
    div {
      &:first-child {
        padding: 8px 16px;
      }
      &:last-child {
        padding: 4px 16px;
      }
    }
  }
`;

export const BonusContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  border-radius: 16px;
  padding: 12px;
  background: #1b1c20;
`;
export const BlueTable = styled.div`
  width: 100%;
  max-width: calc(100vw - 48px);
  ${TableContainer} {
    thead {
      background: var(--Blue-Base, #0085ff);
    }
  }
`;
export const BonusItem = styled.div`
  border-radius: 8px;
  background: #0a0a0c;
  padding: 10px;
`;
export const BonusHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  & > p {
    width: max-content;
    border-radius: 35px;
    padding: 6px 10px;
    font-family: "AnekLatin-Regular";
    &:nth-child(1) {
      font-size: 13px;
      background: #444750;
      color: #fff;
    }
    &:nth-child(2) {
      font-family: "PlusJakartaSans";
      font-size: 11px;
    }
  }
`;
export const BonusBenefit = styled.div`
  border-radius: 8px;
  background: rgba(170, 214, 255, 0.15);
  padding: 8px;
  display: flex;
  justify-content: space-between;
  img{
    max-height: 60px;
  }
  div {
    & > p {
      &:nth-child(1) {
        color: #878ea0;
        font-family: "AnekLatin-Regular";
        text-transform: capitalize;
        margin-bottom: 5px;
        font-size: 16px;
      }
      &:nth-child(2) {
        color: #dce3eb;
        font-family: "PlusJakartaSans";
        font-size: 14px;
      }
    }
  }
`;
