import { useContext, useEffect, useState } from "react";
import { MenuContext } from "../../Provider/MenuProvider";
import {
  BlueTable,
  BonusBalanceBox,
  BonusBalanceBoxContent,
  BonusBalanceBoxTitle,
  BonusBenefit,
  BonusContent,
  BonusHeader,
  BonusItem,
  BonusWapper,
  ProgessContent,
} from "./styled";
import { BoxTitleRadius } from "../Dashboard/styled";
import Table from "../../components/Table";
import { RootState, dispatch, useSelector } from "../../store/reDucStore";
import { getBonusBalance } from "../../store/Slices/User";
import { formatNumber } from "../../utils/formatNumber";
import { getTokenInfo } from "../../store/Slices/Token";
import { ContextProviderWrapper } from "../../components/Context";
import { getNetwordInfo } from "../../store/Slices/NetWord";
import { useTonAddress } from "@tonconnect/ui-react";
import LevelIcon from "../../assets/Dashboard/LevelIcon1.png";
import LevelIcon0 from "../../assets/Dashboard/LevelIcon.png";
import LevelIcon2 from "../../assets/Dashboard/LevelIcon2.png";
import LevelIcon3 from "../../assets/Dashboard/LevelIcon3.png";
import LevelIcon4 from "../../assets/Dashboard/LevelIcon4.png";
import LevelIcon5 from "../../assets/Dashboard/LevelIcon5.png";
import LevelIcon6 from "../../assets/Dashboard/LevelIcon6.png";

const Bonus = () => {
  const { setTitle }: any = useContext(MenuContext);
  const { isMobile } = useContext(ContextProviderWrapper)!;
  useEffect(() => {
    setTitle("Bonus");
  }, []);
  const [balance, setBalance] = useState<any>();
  const [valueProgess, setValueProgess] = useState<any>({
    number: 0,
    Progess: 0,
    nameLevel: "",
  });
  const TokenInfo = useSelector((state: RootState) => state.token.TokenInfo);
  const fechApi = async () => {
    let res = await dispatch(getBonusBalance());
    dispatch(getTokenInfo());
    dispatch(getNetwordInfo());
    setBalance(res?.payload?.data);
  };

  const NetwordInfo = useSelector(
    (state: RootState) => state.network.NetwordInfo
  );
  useEffect(() => {
    if (
      balance &&
      NetwordInfo?.left_team_revenue?.usd &&
      NetwordInfo?.right_team_revenue?.usd
    ) {
      let value =
        NetwordInfo?.left_team_revenue?.usd <
        NetwordInfo?.right_team_revenue?.usd
          ? NetwordInfo?.left_team_revenue?.usd
          : NetwordInfo?.right_team_revenue?.usd;
      if (value < 50000) {
        setValueProgess({
          number: 50000 - value,
          Progess: (value / 50000) * 100,
          nameLevel: "Member",
        });
      } else if (value < 150000) {
        setValueProgess({
          number: 150000 - value,
          Progess: (value / 150000) * 100,
          nameLevel: "Manager 1",
        });
      } else if (value < 500000) {
        setValueProgess({
          number: 500000 - value,
          Progess: (value / 500000) * 100,
          nameLevel: "Manager 2",
        });
      } else if (value < 1000000) {
        setValueProgess({
          number: 1000000 - value,
          Progess: (value / 1000000) * 100,
          nameLevel: "Manager 3",
        });
      } else if (value < 5000000) {
        setValueProgess({
          number: 5000000 - value,
          Progess: (value / 5000000) * 100,
          nameLevel: "President 1",
        });
      } else if (value < 10000000) {
        setValueProgess({
          number: 10000000 - value,
          Progess: (value / 10000000) * 100,
          nameLevel: "President 2",
        });
      } else {
        setValueProgess({
          number: 0,
          Progess: 100,
          nameLevel: "President 3",
        });
      }
    } else {
      setValueProgess({
        number: 50000,
        Progess: 0,
        nameLevel: "Member",
      });
    }
  }, [TokenInfo, balance]);

  const address = useTonAddress();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      fechApi();
    }
  }, [localStorage.getItem("token"), address]);
  return (
    <BonusWapper>
      <BonusBalanceBox>
        <BonusBalanceBoxTitle>
          <p>Weak Team Revenue</p>
        </BonusBalanceBoxTitle>
        <BonusBalanceBoxContent>
          <h3>
            {balance?.total_weak_branch_revenue?.token &&
              formatNumber(
                balance?.total_weak_branch_revenue?.token?.toFixed(2)
              )}{" "}
            <span>TON</span>
          </h3>
          <p>
            ~$
            {balance?.total_weak_branch_revenue?.usd
              ? formatNumber(
                  balance?.total_weak_branch_revenue?.usd?.toFixed(2)
                )
              : "0"}
          </p>
          <ProgessContent value={valueProgess.Progess}>
            <div>
              <p>Your level:</p>
              <p>{valueProgess.nameLevel}</p>
            </div>
            <div>
              <p>Next level need:</p>
              <h6>{valueProgess.number}</h6>
            </div>
          </ProgessContent>
        </BonusBalanceBoxContent>
      </BonusBalanceBox>
      <BonusContent>
        <BoxTitleRadius>
          <h3>Team Matching & Bonus</h3>
        </BoxTitleRadius>
        {!isMobile && (
          <BlueTable>
            <Table
              data={data}
              keyObj={[
                { th: "Ranking", key: "Ranking" },
                // { th: "Conditions", key: "Conditions" },
                { th: "Weak Team Revenue", key: "Weak_Team_Revenue" },
                { th: "Benefits", key: "Benefits" },
              ]}
            />
          </BlueTable>
        )}
        {isMobile && (
          <>
            {data.map((item, index) => {
              return (
                <BonusItem key={index}>
                  <BonusHeader>
                    <p>{item.Ranking}</p>
                    <p
                      style={{
                        backgroundColor: item.background,
                        color: item.color,
                      }}
                    >
                      Weak Team Revenue: {item.Weak_Team_Revenue}
                    </p>
                  </BonusHeader>
                  <BonusBenefit>
                    <div>
                      <p>Benefit</p>
                      <p>{item.Benefits}</p>
                    </div>
                    <img src={item.icon}/>
                  </BonusBenefit>
                </BonusItem>
              );
            })}
          </>
        )}
      </BonusContent>
    </BonusWapper>
  );
};
export default Bonus;

const data = [
  {
    Ranking: "Manager 1",
    Conditions: "2 F1 in 2 Teams",
    Weak_Team_Revenue: "$50,000",
    Benefits: "$1,000",
    background: "#CCE7FF",
    color: "",
    icon: LevelIcon,
  },
  {
    Ranking: "Manager 2",
    Conditions: "2 F1 in 2 Teams",
    Weak_Team_Revenue: "$150,000",
    Benefits: "$3,000",
    background: "#AAD6FF",
    color: "",
    icon: LevelIcon2,
  },
  {
    Ranking: "Manager 3",
    Conditions: "2 F1 in 2 Teams",
    Weak_Team_Revenue: "$500,000",
    Benefits: "$5,000 + 1% Monthly Network Revenue",
    background: "#80C2FF",
    color: "",
    icon: LevelIcon3,
  },
  {
    Ranking: "President 1",
    Conditions: "2 F1 in 2 Teams",
    Weak_Team_Revenue: "$1,000,000",
    Benefits: "$10,000 + 2% Monthly Network Revenue",
    background: "#55AEFF",
    color: "",
    icon: LevelIcon4,
  },
  {
    Ranking: "President 2",
    Conditions: "2 F1 in 2 Teams",
    Weak_Team_Revenue: "$5,000,000",
    Benefits: "$50,000 + 1% Monthly TON Holding Revenue",
    background: "#2B99FF",
    color: "#fff",
    icon: LevelIcon5,
  },
  {
    Ranking: "President 3",
    Conditions: "2 F1 in 2 Teams",
    Weak_Team_Revenue: "$10,000,000",
    Benefits: "$100,000 + 2% Monthly TON Holding Revenue",
    background: "#0085FF",
    color: "#fff",
    icon: LevelIcon6,
  },
];
