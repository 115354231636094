import { WagmiProvider } from "wagmi";
import "./App.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { bsc } from "viem/chains";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";
import { createWeb3Modal } from "@web3modal/wagmi/react";
import { Toaster } from "react-hot-toast";
import { AppContainer } from "./styled";
import { Navigate, Route, Routes } from "react-router-dom";
import LayoutCommon from "./layout";
import Dashboard from "./pages/Dashboard";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import { MenuProvider } from "./Provider/MenuProvider";
import Staking from "./pages/Staking";
import Bonus from "./pages/Bonus";
import Affiliate from "./pages/Affilliate";
import HistoryPage from "./pages/History";
import { Provider } from "react-redux";
import { store } from "./store/store";
import Withdraw from "./pages/Withdraw";
import NetworkPage from "./pages/Network";
import TokenomicsPage from "./pages/Tokenomic";
import RoadMapPage from "./pages/Roadmap";

function App() {
  const queryClient = new QueryClient();

  const metadata = {
    name: "App Ton Holding",
    description: "App Ton Holding",
    url: "",
    icons: [""],
  };

  const projectId = "c06e8851ab55aa5954efec46c47d233e";
  const chains = [bsc] as const;
  const config = defaultWagmiConfig({
    chains,
    projectId,
    metadata,
  });

  createWeb3Modal({
    wagmiConfig: config,
    projectId,
  });

  return (
    // <WagmiProvider config={config}>
    //   <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <TonConnectUIProvider manifestUrl="https://ton-holding-dev.netlify.app/tonmanifest.json">
        <MenuProvider>
          <Toaster
            position="top-right"
            reverseOrder={false}
            toastOptions={{
              style: {
                zIndex: 100000,
                fontFamily: "PlusJakartaSans"
              },
            }}
          />
          <AppContainer>
            <Routes>
              <Route element={<LayoutCommon />}>
                <Route index path="/" element={<Dashboard />} />
                <Route path="/:ref" element={<Dashboard />} />
                <Route path="staking" element={<Staking />} />
                <Route path="bonus" element={<Bonus />} />
                <Route path="affiliate" element={<Affiliate />} />
                <Route path="history" element={<HistoryPage />} />
                <Route path="Wallet" element={<Withdraw />} />
                <Route path="network" element={<NetworkPage />} />
                <Route path="tokenomics" element={<TokenomicsPage />} />
                <Route path="roadmap" element={<RoadMapPage />} />
              </Route>
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </AppContainer>
        </MenuProvider>
      </TonConnectUIProvider>
    </Provider>

    //   </QueryClientProvider>

    // </WagmiProvider>
  );
}

export default App;
