import { StakingSwitchTabs } from "../Staking/styled";
import {
  PannerNetword,
  NetWortContent,
  NetworkWapper,
  NetworkInner,
} from "./style";
import { useContext, useEffect, useState } from "react";
import { RootState, useDispatch, useSelector } from "../../store/reDucStore";
import { getNetwordInfo } from "../../store/Slices/NetWord";
import { useTonAddress } from "@tonconnect/ui-react";
import { getUserList } from "../../store/Slices/User";
import { MenuContext } from "../../Provider/MenuProvider";
import { ContextProviderWrapper } from "../../components/Context";
import LeftTeam from "./LeftTeam";
import RightTeam from "./RightTeam";
import { Video } from "../Dashboard/styled";

const NetworkPage = () => {
  const { setTitle , setDes }: any = useContext(MenuContext);
  const dispatch = useDispatch();
  useEffect(() => {
    setTitle("Network");
    setDes("Expand your network and get more rewards")
  }, []);

  const address = useTonAddress();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(getNetwordInfo());
      dispatch(getUserList());
    }
  }, [localStorage.getItem("token"), address]);
  
  const NetwordInfo = useSelector(
    (state: RootState) => state.network.NetwordInfo
  );
  const [stateSwitch, setStateSwitch] = useState(1)
  const { isMobile } = useContext(ContextProviderWrapper)!
  const handleSwitchTabMobile = () => {
    switch (stateSwitch) {
      case 1:
        return <LeftTeam />
      case 2:
        return <RightTeam />
    }
  }

  return (
    <NetworkWapper>
      <NetworkInner>
        <PannerNetword>
          <div>
            <h3>Network</h3>
            <p>Invite your friend by referral code to receive 8% commission 
              are awaiting you</p>
          </div>
          
        {isMobile && (
          <Video
            src="https://www.youtube.com/embed/J_xnj3xwF8k?autoplay=1&loop=1&playlist=J_xnj3xwF8k"
            title="Introduce iTON Game Clicker"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></Video>
        )}
        </PannerNetword>
        <NetWortContent>
          {isMobile && <StakingSwitchTabs>
            {tabData.map((item, index) => {
              return (
                <div style={{
                  backgroundColor: item.id === stateSwitch ? "#0085FF" : "transparent",
                  color: item.id === stateSwitch ? "#fff" : "#878EA0"
                }} onClick={() => {
                  setStateSwitch(item.id)
                }} key={index}>
                  {item.text}
                  <span>{ item.id === 1 ? NetwordInfo?.left_team_number || 0 : NetwordInfo?.right_team_number || 0 }</span>
                </div>
              )
            })}
          </StakingSwitchTabs>}
          {!isMobile ? <>
            <LeftTeam />
            <RightTeam />
          </> : <>
            {handleSwitchTabMobile()}
          </>}
        </NetWortContent>
      </NetworkInner>
    </NetworkWapper>
  );
};
export default NetworkPage;

const tabData = [
  {
    id: 1,
    text: "Left Team"
  },
  {
    id: 2,
    text: "Right Team"
  },
]