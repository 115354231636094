import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  AuthGetPayloadResponse,
  AuthLoginRequest,
  TokenResponse,
} from "./authApiTypes";
import { ConnectAdditionalRequest } from "@tonconnect/ui-react";
import type { RootState } from "../store";

const BACKEND_URL = "https://dev-api.tonholding.io/";
// const BACKEND_URL = "http://52.194.13.200:4007/";
const BASE_URL = `${BACKEND_URL}auth/`;

export const authApiV1 = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    // credentials: "include",
    // prepareHeaders: (headers, { getState }) => {
    //   headers.set('Access-Control-Allow-Origin', '*')
    //   return headers
    // }
  }),
  endpoints: (builder) => ({
    getPayload: builder.mutation<ConnectAdditionalRequest, void>({
      query: () => `get-payload`,
      transformResponse: (response: AuthGetPayloadResponse) => {
        return {
          tonProof: response.payload,
        };
      },
    }),
    login: builder.mutation<TokenResponse, AuthLoginRequest>({
      query(data) {
        return {
          url: "proof",
          method: "POST",
          body: data,
        };
      },
    }),
    refreshToken: builder.mutation<TokenResponse, void>({
      query() {
        return {
          url: "refresh_token",
          method: "POST",
        };
      },
    }),
  }),
});

export const {
  useGetPayloadMutation,
  useLoginMutation,
  useRefreshTokenMutation,
} = authApiV1;
