import styled from "styled-components";
import cartBg from "../../assets/Dashboard/Card.png";
import BgBoxTotal from "../../assets/Dashboard/TotalBg.png";
import leftBg from "../../assets/Dashboard/leftBg.png";
import ProgesBg from "../../assets/Dashboard/progressingBg.png";
import TopThum from "../../assets/Dashboard/TopThum.svg";
import BottomThum from "../../assets/Dashboard/ThumBottom.png";
import logo from "../../assets/Dashboard/VectorTon.png";
import BgLevel from "../../assets/Dashboard/BgLevel.png";
import BgLevel0 from "../../assets/Dashboard/BgLevel0.png";

export const DashboardWapper = styled.div`
  width: 100%;
  overflow-y: hidden;
  gap: 28px;
  max-width: 1660px;
  margin: 0 auto;
  padding: 30px 24px;
  @media only screen and (max-width: 991px) {
    gap: 18px;
  }
  @media only screen and (max-width: 820px) {
    flex-direction: column-reverse;
    gap: 30px;
  }
  @media screen and (max-width: 767px) {
    padding: 25px 15px 110px;
  }
`;

export const DashboardTotal = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background-image: url(${BgBoxTotal});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  height: 202px;
  border-radius: 16px;
  padding: 32px 46px;
  @media only screen and (max-width: 768px) {
    padding: 0px;
    height: unset;
    background-position: -400px center;
    max-width: 100%;
    min-height: 120px;
  }
`;
export const Video = styled.iframe`
  width: 100%;
  aspect-ratio: 1236/695;
  border-radius: 16px;
  display: none;
  @media only screen and (max-width: 768px) {
    display: flex;
  }
`;
export const DashboardTitle = styled.div`
  h3 {
    color: var(--Blue-50, #2b99ff);
    font-family: "AnekLatin-Regular";
    font-size: 37px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    margin-bottom: 8px;
    span {
      background: linear-gradient(90deg, #fff 74.28%, #999 96.71%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  p {
    color: var(--Light-Gray-Base, #dce3eb);
    font-family: "AnekLatin-Regular";
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 70% */
  }
  @media only screen and (max-width: 768px) {
    display: none;
    h3 {
      margin-bottom: 15px;
      font-size: 22px;
    }
    p {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

export const DashboardContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 24px;
  gap: 16px;
  @media only screen and (max-width: 820px) {
    flex-direction: column;
  }
  @media screen and (max-width: 767px) {
    padding-top: 15px;
    gap: 0;
  }
`;

export const DashboardLeft = styled.div`
  width: 40%;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 820px) {
    width: 100%;
  }
  /* @media only screen and (max-width: 768px) {
    display: none;
  } */
`;
export const DashboardRight = styled.div`
  width: calc(60% - 14px);
  align-self: stretch;
  display: flex;
  flex-direction: column;
  /* @media only screen and (max-width: 1024px) {
    width: 100%;
  } */
  @media only screen and (max-width: 820px) {
    width: 100%;
  }
`;

export const DashboardTotalBalance = styled.div`
  width: 100%;
  border-radius: 24px;
  overflow: hidden;
  margin-bottom: 24px;
  border-radius: 16px;
  background-image: url(${leftBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 18px;
  @media screen and (max-width: 767px) {
    margin-bottom: 15px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
`;
export const ImgIcon = styled.img`
  max-width: 12.5%;
  @media screen and (max-width: 767px) {
    max-width: 78px;
    height: 78px;
  }
`;
export const BoxTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  h3 {
    font-size: 21px;
    line-height: 100%;
    font-family: "AnekLatin-Regular";
    font-weight: 600;
    color: #004380;
  }
  img {
    width: 20px;
    border: 0.1px solid #fff;
    border-radius: 50%;
  }
  @media screen and (max-width: 767px) {
    h3 {
      margin-bottom: 10px;
    }
  }
`;
export const BoxTotalContent = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top right;
  display: flex;
  flex-direction: column;
  gap: 50px;
`;
export const TotalValue = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  h4 {
    color: var(--Blue-10, #717685);
    font-family: AnekLatin-Regular;
    font-weight: 500;
    font-size: 25px;
  }
  /* @media only screen and (max-width: 540px) {
    padding-top: 46px;
  } */
`;
export const BigNumber = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  h3 {
    font-family: "PlusJakartaSans";
    line-height: 100%;
    color: #0085ff;
    font-size: 40px;
    font-weight: 500;
    line-height: 80%;
    letter-spacing: 0.6px;
    display: flex;
    gap: 8px;
    align-items: center;
    img {
      width: 32px;
    }
  }
  /* @media only screen and (max-width: 540px) {
    h3 {
      font-size: 24px;
    }
  } */
  @media screen and (max-width: 767px) {
    align-items: center;
  }
`;
export const BoxChangeValue = styled.div<{ color: any; bg: any; rotate?: any }>`
  padding: 8px 10px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ bg }) => bg};
  svg {
    transform: rotateZ(${({ rotate }) => (rotate ? "180deg" : "0deg")});
  }
  p {
    color: #17f147;
    leading-trim: both;
    text-edge: cap;
    font-family: "PlusJakartaSans";
    font-size: 15px;
    font-weight: 400;
    line-height: 100%;
    color: ${({ color }) => color};
  }
`;
export const AccountBox = styled.div`
  width: 100%;
`;
export const BoxTitleRadius = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 4px 4px 16px;
  min-height: 48px;
  gap: 8px;
  width: 100%;
  justify-content: space-between;
  background-color: #5a5f6b;
  border-radius: 34px;
  h3 {
    font-size: 21px;
    line-height: 100%;
    font-family: "AnekLatin-Regular";
    font-weight: 700;
    background: linear-gradient(90deg, #fff 51.48%, #2d2f35 171.59%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media only screen and (max-width: 767px) {
    padding: 8px 0 8px 16px;
    background-color: #5a5f6b00;
    border-radius: 10px;
    min-height: unset;
  }
  @media only screen and (max-width: 430px) {
    border-radius: 5px;
    .Text-his {
      font-size: 17px;
    }
    .his {
      max-width: 160px;
    }
  }
`;
export const ProgressingBg = styled.div`
  background-image: url(${ProgesBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 16px 16px 24px;
  display: flex;
  align-items: center;
  border-radius: 16px;
  margin-top: 4px;
  position: relative;
  width: calc(100% - 2px);
  margin: 4px auto 0px;
  z-index: 1;
  overflow: hidden;
  flex: 1;
  &::after {
    content: "";
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    position: absolute;
    top: -1px;
    left: -1px;
    border-radius: 16px;
    background: linear-gradient(180deg, #101b2d 0%, rgba(27, 28, 32, 0) 60.18%);
    z-index: -1;
  }
`;
export const ProgesContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  div {
    h3 {
      color: var(--Gray-40, #afb4c0);
      font-family: "AnekLatin-Regular";
      font-size: 19px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 88.889% */
      letter-spacing: 0.18px;
    }
    p {
      color: var(--Light-Gray-10, #f8f9fb);
      font-family: "PlusJakartaSans";
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 83.333% */
      letter-spacing: 0.36px;
    }
  }
`;
export const ProgesBar = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ProgesBox = styled.div<{ percent?: any }>`
  position: relative;
  width: fit-content;
  height: fit-content;

  &::before {
    width: 17%;
    content: "";
    position: absolute;
    background-image: url(${logo});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    aspect-ratio: 1;
    top: 15%;
    left: 50%;
    z-index: 10;
    transform: translateX(-50%) translateY(50%);
    /* box-shadow: 0px 0px 15px 0px #0085FF; */
  }
  &::after {
    height: 85%;
    content: "";
    position: absolute;
    background-image: url(${BottomThum});
    background-position: center;
    aspect-ratio: 445/125;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%) translateY(50%);
  }
`;
export const ProgesValue = styled.div<{ percent?: any }>`
  width: 100%;
  height: calc(100% - 8px);
  position: absolute;
  bottom: 0px;
  left: 0px;
  border-radius: 0% 0% 94px 94px / 0% 0% 24px 24px;
  /* overflow: hidden; */

  &::before {
    width: 100%;
    aspect-ratio: 76.27/15.2;
    content: "";
    position: absolute;
    bottom: ${({ percent }) => (percent ? percent : 0)}%;
    left: 0px;
    background-image: url(${TopThum});
    transform: translateY(0%);
    z-index: 2;
  }
  &::after {
    width: 100%;
    height: calc(${({ percent }) => (percent ? percent : 0)}% + 15px);
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0px;
    border-radius: 94px / 24px;
    background: linear-gradient(180deg, #80c2ff 38.7%, #0085ff 100.6%);
  }
`;
export const ProgesTitle = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
  transform: translateX(-50%) translateY(-50%);
  h3 {
    color: var(--Light-Gray-10, #f8f9fb);
    text-align: center;
    font-family: "PlusJakartaSans";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 100% */
    letter-spacing: 0.3px;
  }
  p {
    color: var(--Light-Gray-Base, #dce3eb);
    text-align: center;
    font-family: "PlusJakartaSans";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 166.667% */
    letter-spacing: 0.18px;
  }
`;

export const OverviewGroupMB = styled.div`
  h1 {
    display: none;
  }
  @media only screen and (max-width: 768px) {
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    border-radius: 16px;
    background: var(--Gray-90, #2d2f35);
    h1 {
      display: block;
      font-size: 22px;
      line-height: 100%;
      font-family: "AnekLatin-Regular";
      font-weight: 700;
      background: linear-gradient(90deg, #fff 51.48%, #2d2f35 171.59%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      display: flex;
      padding: 5px 4px 10px 16px;
      align-items: center;
      gap: 10px;
      align-self: stretch;
    }
  }
`;

export const ProcessingMB = styled.div`
  display: none;
  @media only screen and (max-width: 768px) {
    display: block;
    margin: 15px 0;
  }
`;

export const ProcessingBlockWrapMB = styled.div`
  width: 100%;
  padding: 19px;
  background-image: url(${BgLevel});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  flex: 1;
  position: relative;
  height: 200px;
  .img-battery {
    max-width: 280px;
    position: absolute;
    bottom: 0;
    right: 0;
    img {
      width: 100%;
    }
  }
  .img-level {
    max-width: 120px;
  }
`;

export const ContentInBatteryMB = styled.div`
  @media only screen and (max-width: 768px) {
    position: absolute;
    right: 72px;
    top: 70px;
    .img-ton {
      top: 0;
    }
    h2 {
      color: #f8f9fb;
      text-align: center;
      font-family: "Plus Jakarta Sans";
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.3px;
    }
    h1 {
      color: #dce3eb;
      text-align: center;
      font-family: "Plus Jakarta Sans";
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.18px;
      padding: 0;
      display: block;
      margin-top: 5px;
    }
  }
`;

export const ProcessingBlockMB = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
  h3 {
    color: var(--Gray-40, #afb4c0);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 100% */
    letter-spacing: 0.16px;
  }
  h2 {
    color: var(--Light-Gray-10, #f8f9fb);
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 100% */
    letter-spacing: 0.3px;
  }
`;

export const InvestmentMB = styled.div`
  width: 100%;
  height: 190px;
  flex-shrink: 0;
  border-radius: 16px;
  background: linear-gradient(180deg, #101b2d 0%, rgba(27, 28, 32, 0) 60.18%);
`;

export const YourInvestmentMB = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const OverviewGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  margin-bottom: 24px;
  flex-wrap: wrap;
  margin-top: 4px;
  @media only screen and (max-width: 768px) {
    display: flex;
    gap: 10px;
    margin-bottom: 0;
  }
`;
export const IconBox = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #444750;
  border-radius: 50%;
`;
export const OverviewBox = styled.div`
  width: calc(50% - 6px);
  align-self: stretch;
  padding: 14px;
  border-radius: 16px;
  background: var(--Gray-100, #1b1c20);
  background-size: 200%;
  /* background-image: url(${cartBg}); */
  background-position: top left;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  gap: 28px;
  box-shadow: 0px 0px 0px 0px #5a5f6b;
  &:hover {
    background-color: #2d2f35;
    background-position: top right;
    box-shadow: 0px 4px 0px 0px #5a5f6b;
  }
`;
export const BoxValue = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  p {
    color: var(--Gray-10, #e7e8ec);
    font-family: anek-odia-400-normal;
    color: var(--Gray-30, #c3c6cf);
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 88.889% */
    letter-spacing: 0.18px;
  }
  h3 {
    color: var(--Light-Gray-10, #f8f9fb);
    font-family: "PlusJakartaSans";
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 83.333% */
    letter-spacing: 0.36px;
  }
  span {
    color: var(--Light-Gray-10, #f8f9fb);
    font-family: "PlusJakartaSans";
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    margin: 0 0 0 10px;
  }
`;

export const BoxLevel = styled.div<{ level?: any }>`
  width: 100%;
  padding: 32px 64px 32px 16px;
  background-image: url(${({ level }) => (level ? BgLevel : BgLevel0)});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  flex: 1;
`;
export const BoxLevelContent = styled.div<{ level?: any }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
  width: 50%;
  min-width: 105px;
  gap: 20px;
  div {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  h3 {
    color: var(--Gray-40, #afb4c0);
    font-family: "AnekLatin-Regular";
    font-size: 19px;
    font-weight: 400;
    line-height: 16px; /* 88.889% */
    letter-spacing: 0.18px;
  }
  p {
    color: var(--Light-Gray-10, #f8f9fb);
    font-family: "AnekLatin-Regular";
    font-size: 27px;
    font-weight: 500;
    line-height: 20px; /* 76.923% */
    letter-spacing: 0.39px;
    background: linear-gradient(
      90deg,
      ${({ level }) => {
          switch (level) {
            case "Manager 1":
              return "#8EBDFF";
              break;
            case "Manager 2":
              return "#8EBDFF";
              break;
            case "Manager 3":
              return "#8EBDFF";
              break;
            case "President 1":
              return "#FFF07E ";
              break;
            case "President 2":
              return "#FFF07E";
              break;
            case "President 3":
              return "#FFF07E";
              break;
            default:
              return "#fff";
          }
        }}
        0%,
      #fff 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;
export const BoxProgess = styled.div`
  h5 {
    color: var(--Gray-40, #afb4c0);
    font-family: "AnekLatin-Regular";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 123.077% */
    letter-spacing: 0.13px;
    display: flex;
    align-items: center;
  }
  div{
    width: 100%;
    border-radius: 50px;
    height: 21px;
  }
`;
export const BoxLevelImg = styled.img`
  width: 25%;
`;
export const AccountBlock = styled.div`
  @media screen and (max-width: 767px) {
    padding: 12px;
    border-radius: 16px;
    background: #2d2f35;
    margin-bottom: 15px;
  }
`;

export const CarouselNewsContainer = styled.div<{ isMobile: boolean }>`
  border-radius: 16px;
  background: ${({ isMobile }) => (isMobile ? "transparent" : "#1b1c20")};
  padding: ${({ isMobile }) => (isMobile ? "0" : "15px")};
  .img-pagination {
    max-width: 50px;
    img {
      width: 100%;
    }
  }
`;

export const CarouseMBPagination = styled.div`
  display: flex;
  justify-content: center;
`;

export const CarouselNewsTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
  .img-star {
    max-width: 30px;
    img {
      width: 100%;
    }
  }
  h1 {
    font-family: "Anek Latin";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: linear-gradient(90deg, #fff 51.48%, #2d2f35 171.59%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export const CarouselNewsParents = styled.div`
  width: 100%;
  @media screen and (max-width: 767px) {
    width: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const CarouselNewsParentsWrap = styled.div`
  width: 100%;
  /* max-width: 1000px; */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CarouselNewsItem = styled.div`
  .img-slider {
    max-width: 500px;
    img {
      width: 100%;
    }
  }
  @media screen and (max-width: 767px) {
    .img-slider {
      max-width: 100%;
      img {
        width: 100%;
      }
    }
  }
`;
