import { useSelector } from "react-redux";
import { BoxNotification, Submit } from "../../Staking/styled";
import { WithdrawInput, WithdrawInputAmount } from "../style";
import { formatNumber } from "../../../utils/formatNumber";
import NotiIcon from "../../../assets/Staking/CompoundBadge.svg";

const WidthRawBox = ({
  amount,
  setAmount,
  fee,
  setFee,
  handleWithdraw,
  loadingSub,
}: any) => {
  const balanceWithdraw = useSelector(
    (state: any) => state.withdraw.balanceWithdraw
  );
  const TokenInfo = useSelector((state: any) => state.token.TokenInfo);
  const Fee = useSelector((state: any) => state.withdraw.settingList);
  return (
    <WithdrawInput>
      <WithdrawInputAmount>
        <h3>Amount</h3>
        <div>
          <input
            type="number"
            pattern="0.00"
            value={amount ? amount : ""}
            onChange={(e: any) => {
              e.target.value >= 0 && setAmount(e.target.value);
            }}
          />
          <button
            onClick={() => {
              setAmount(Math.floor(Number(balanceWithdraw?.money_token)) || 0);
            }}
          >
            Max
          </button>
        </div>
        <p>
          ~$
          {TokenInfo?.prices?.USD
            ? formatNumber(
                (Number(amount) * TokenInfo?.prices?.USD)?.toFixed(2)
              )
            : 0}{" "}
        </p>
        <BoxNotification>
          <div>
            <img src={NotiIcon} alt="icon" />
            <p>Fee Withdraw</p>
          </div>
          <h6>
            <span>{fee}%</span>{" "}
            {`(~$${
              fee
                ? `${
                    TokenInfo?.prices?.USD
                      ? formatNumber(
                          (
                            (Number(amount) * TokenInfo?.prices?.USD * fee) /
                            100
                          )?.toFixed(2)
                        )
                      : 0
                  }`
                : "0"
            })`}
          </h6>
        </BoxNotification>
        <h3>Minimum withdrawal $10</h3>
        <Submit
          onClick={() => {
            !loadingSub && handleWithdraw();
          }}
          style={{
            opacity:
              amount && Number(amount) <= balanceWithdraw?.money_token && Number(amount) * TokenInfo?.prices?.USD >= 10
                ? "1"
                : "0.5",
          }}
        >
          {" "}
          {loadingSub ? "Loading..." : "Withdraw"}
        </Submit>
      </WithdrawInputAmount>
    </WithdrawInput>
  );
};
export default WidthRawBox;
