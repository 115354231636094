import {
  TonConnectButton,
  useIsConnectionRestored,
  useTonConnectUI,
  useTonWallet,
} from "@tonconnect/ui-react";
import styled from "styled-components";
import { useTonAddress } from "@tonconnect/ui-react";
import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  useGetPayloadMutation,
  useLoginMutation,
} from "../../store/api/authApiSlice";
import { removeToken, updateToken } from "../../store/features/authSlice.ts";
import { getCookie } from "../../utils/cookie";
import { instance } from "../../store/instance";
import { useParams } from "react-router-dom";
import { MenuContext, MenuProvider } from "../../Provider/MenuProvider";
import ModalDisConnect from "../ModalDisConnect";
import toast from "react-hot-toast";
import ModalNoti from "../Modal/ModalNoti";

const BtnConnect = () => {
  const address = useTonAddress();
  const [tonConnectUI] = useTonConnectUI();
  const { handleOpenCustom } = useContext(MenuContext);
  const [reLoadState, setReLoadState] = useState(false);
  const handleRefest = async () => {
    const tk: any = await localStorage.getItem("token");

    if (reLoadState) {
      return;
    }
    if (!tk || tk === "null" || tk === "undefined") {
      console.log("address", address);
      if (address) {
        console.log("address", address);
        tonConnectUI.disconnect();
      }
    }
  };
  useEffect(() => {
    handleRefest();
  }, [localStorage.getItem("token")]);

  useAuth(setReLoadState, reLoadState);

  useEffect(() => {
    if (address) {
      handleOpenCustom(<ModalNoti />);
    }
  }, [address]);

  return (
    <BtnWapper>
      <BtnOpacity
        onClick={() => {
          !address
            ? tonConnectUI.openModal()
            : handleOpenCustom(<ModalDisConnect />);
        }}
      ></BtnOpacity>
      {!address && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <rect
            x="0.666504"
            y="4"
            width="16.6667"
            height="13.3333"
            rx="4.16667"
            stroke="white"
            stroke-width="1.25"
          />
          <path
            d="M14.8332 4.41666V4.41666C14.8332 2.48075 13.0563 1.03247 11.1602 1.42285L3.99295 2.89847C2.05624 3.2972 0.666504 5.00221 0.666504 6.97954L0.666504 9.83332"
            stroke="white"
            stroke-width="1.25"
          />
          <path
            d="M4 13.5833H9"
            stroke="white"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M11.5 10.6667C11.5 9.51608 12.4327 8.58334 13.5833 8.58334H17.3333V12.75H13.5833C12.4327 12.75 11.5 11.8173 11.5 10.6667V10.6667Z"
            stroke="white"
            stroke-width="1.25"
          />
          <path
            d="M13.583 10.6667H13.7497"
            stroke="white"
            stroke-width="1.25"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      )}
      <p>
        {!address
          ? "Connect Wallet"
          : `${address.slice(0, 4)}...${address.slice(-4)}`}
      </p>
    </BtnWapper>
  );
};
export default BtnConnect;

const TokenCookieKey = process.env.REACT_APP_LOCAL_TOKEN_COOKIE_KEY ?? "";
const REFERRAL_LINK_KEY = "ref";

export const getTokenCookie = () => {
  const token = getCookie(TokenCookieKey);
  return token;
};

export function useAuth(setReLoadState: any, reLoadState: any) {
  const dispatch = useDispatch();
  const isConnectionRestored = useIsConnectionRestored();

  const [getPayloadRequest]: any = useGetPayloadMutation();
  const [loginRequest] = useLoginMutation();

  const wallet = useTonWallet();
  const [tonConnectUI] = useTonConnectUI();

  const refreshPayloadInterval = useRef<
    ReturnType<typeof setInterval> | undefined
  >();
  const checkTokenCookieInterval = useRef<
    ReturnType<typeof setInterval> | undefined
  >();

  const { ref } = useParams();
  useEffect(() => {
    if (ref) {
      localStorage.setItem(REFERRAL_LINK_KEY, ref);
    }
  }, [ref]);
  const refreshPayload = async () => {
    tonConnectUI.setConnectRequestParameters({ state: "loading" });
    try {
      const value = await getPayloadRequest().unwrap();
      console.log("value", value);
      tonConnectUI.setConnectRequestParameters({
        state: "ready",
        value,
      });
    } catch (e) {
      tonConnectUI.setConnectRequestParameters({
        state: "loading",
      });
    }
  };

  const removeTokenCookie = () => {
    dispatch(removeToken());
    document.cookie = `${TokenCookieKey}=; max-age=50; Expires=Fri, 029 Mar 2024 00:00:00 GMT`;
    if (tonConnectUI.connected) {
      tonConnectUI.disconnect();
    }
  };

  const checkTokenCookie = () => {
    const newToken: any = getTokenCookie();
    if (newToken) {
      dispatch(updateToken(newToken));
    }
  };
  if (!checkTokenCookieInterval.current) {
    checkTokenCookieInterval.current = setInterval(checkTokenCookie, 1000 * 5);
  }

  useEffect(() => {
    const cookieToken: any = getTokenCookie();
    if (cookieToken) {
      dispatch(updateToken(cookieToken));
    }
    if (!wallet) {
      removeTokenCookie();
      refreshPayload();
      refreshPayloadInterval.current = setInterval(
        refreshPayload,
        1000 * 60 * 19
      );
      return;
    }

    if (
      wallet.connectItems?.tonProof &&
      !("error" in wallet.connectItems.tonProof)
    ) {
      try {
        loginRequest({
          address: wallet.account.address,
          network: Number(wallet.account.chain),
          referral_code:
            ref || localStorage.getItem(REFERRAL_LINK_KEY) || undefined,
          proof: {
            timestamp: wallet.connectItems.tonProof.proof.timestamp,
            domain: {
              length_bytes:
                wallet.connectItems.tonProof.proof.domain.lengthBytes,
              value: wallet.connectItems.tonProof.proof.domain.value,
            },
            signature: wallet.connectItems.tonProof.proof.signature,
            payload: wallet.connectItems.tonProof.proof.payload,
            state_init: wallet.account?.walletStateInit,
            public_key: wallet.account?.publicKey,
          },
        })
          .unwrap()
          .then((res: any) => {
            console.log("ressss", res);
            if (res?.token) {
              localStorage.setItem("token", res?.token);
              instance.interceptors.request.use(
                async (config: any) => {
                  config.headers["Authorization"] = res?.token;
                  setReLoadState(!reLoadState);
                  return config;
                },
                (error: any) => {
                  return Promise.reject(error);
                }
              );
            }
            checkTokenCookie();
          });
      } catch (e) {
        toast.error("Login failed. Please reload the page and try again.");
        tonConnectUI.disconnect();
      }
    }
    // else {
    //   removeTokenCookie();
    // }
  }, [wallet]);
}

const BtnWapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: fit-content;
  gap: 10px;
  min-height: 40px;
  padding: 14px 16px;
  border-radius: 34px;
  background: var(--Gray-90, #2d2f35);
  overflow: hidden;
  cursor: pointer;
  p {
    white-space: nowrap;
    color: var(--Light-Gray-10, #f8f9fb);
    font-family: AnekLatin-Regular;
    font-weight: 500;
    font-size: 15px;
    font-weight: 400;
    line-height: 100%;
    vertical-align: middle;
  }
  @media only screen and (max-width: 768px) {
    padding: 10px 16px;
    gap: 10px;
  }
`;
const BtnOpacity = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 5;
`;
