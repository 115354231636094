import { Submit } from "../Staking/styled";
import { BoxTitleRadius } from "../Dashboard/styled";
import { useContext, useEffect, useState } from "react";
import { MenuContext } from "../../Provider/MenuProvider";
import { RootState, useDispatch, useSelector } from "../../store/reDucStore";
import { getTokenInfo } from "../../store/Slices/Token";
import toast from "react-hot-toast";
import { useTonAddress } from "@tonconnect/ui-react";
import NewBg from "../../assets/Withdraw/wdrawBg.png";
import wdrawIcon from "../../assets/Withdraw/WithdrawIcon.png";
import {
  TranferSubmit,
  WidthrawSubmit,
  getBalance,
  getFee,
  getWidthrawList,
} from "../../store/Slices/Withdraw";
import { getDasboardInfo } from "../../store/Slices/Dashboard";
import styled from "styled-components";
import { PannerNetword } from "../Network/style";
import {
  TableWithdrawMobile,
  WithdrawBalance,
  WithdrawBalanceText,
  WithdrawConten,
  WithdrawHisBox,
  WithdrawInputBox,
  WithdrawInputGroup,
  WithdrawTab,
  WithdrawTabs,
  WithdrawWapper,
} from "./style";
import TableWithdraw from "./Table";
import { ContextProviderWrapper } from "../../components/Context";
import { formatNumber } from "../../utils/formatNumber";
import WidthRawBox from "./Elm/withdrawBox";
import TransferBox from "./Elm/TransferBox";
import ModalConfirm from "./modalConfirm";

const Withdraw = () => {
  const [amount, setAmount] = useState(0);
  const [revierAddress, setRevierAddress] = useState("");
  const { isMobile } = useContext(ContextProviderWrapper)!;
  const [loadingSub, setLoadingSub] = useState(false);
  const { setTitle, setDes, handleOpenCustom, isOpen }: any =
    useContext(MenuContext);
  const [tabsActive, setTabsActive] = useState(1);
  const address = useTonAddress();
  useEffect(() => {
    setTitle("Wallet");
    setDes("Easy to withdraw from your wallet");
  }, []);
  useEffect(() => {
    if (!isOpen) {
      setLoadingSub(false);
    }
  }, [isOpen]);
  const dispatch = useDispatch();

  const TokenInfo = useSelector((state: RootState) => state.token.TokenInfo);
  const WidthrawList = useSelector(   
    (state: RootState) => state.withdraw.WidthrawList
  );
  const balanceWithdraw = useSelector(
    (state: RootState) => state.withdraw.balanceWithdraw
  );
  const Fee = useSelector((state: RootState) => state.withdraw.settingList);
  const [fee, setFee] = useState(0);
  useEffect(() => {
    if (
      Fee?.data?.find((item: any) => item.name === "Fee.WithDraw.Token.Percent")
        ?.value
    ) {
      setFee(
        Number(
          Fee?.data?.find(
            (item: any) => item.name === "Fee.WithDraw.Token.Percent"
          )?.value
        )
      );
    }
  }, [
    Fee?.data?.find((item: any) => item.name === "Fee.WithDraw.Token.Percent")
      ?.value,
  ]);
  useEffect(() => {
    if (localStorage.getItem("token") && address) {
      dispatch(getDasboardInfo());
      dispatch(getTokenInfo());
      dispatch(getWidthrawList());
      dispatch(getBalance());
      dispatch(getFee());
    }
  }, [localStorage.getItem("token"), address]);

  const handleWithdraw = async () => {
    if (!Number(amount) || Number(amount) > balanceWithdraw?.money_token) {
      toast.error("Balance Not Enough");
      return;
    }
    if (Number(amount) * TokenInfo?.prices?.USD < 10) {
      toast.error(`${tabsActive === 2 ? "Transfer" : "Withdraw"} a minimum of $10`);
      return;
    }
    if (!revierAddress && tabsActive === 2) {
      toast.error("Received wallet is invalid");
      return;
    }
    setLoadingSub(true);
    handleOpenCustom(
      <ModalConfirm
        handleConfirm={handleConfirm}
        tabsActive={tabsActive}
        amount={amount}
        address={revierAddress}
      />
    );
  };
  const handleConfirm = async () => {
    let result: any;
    if (tabsActive === 1) {
      result = await dispatch(
        // @ts-ignore
        WidthrawSubmit({
          amount: Number(amount),
          type: "TON",
        })
      );
    } else {
      result = await dispatch(
        // @ts-ignore
        TranferSubmit({
          to_address: revierAddress,
          amount: amount,
        })
      );
    }
    if (tabsActive === 1) {
      if (result.payload.status === 200) {
        dispatch(getDasboardInfo());
        dispatch(getTokenInfo());
        dispatch(getWidthrawList());
        dispatch(getBalance());
        dispatch(getFee());
        toast.success("Successfully");
      } else if (
        result.payload?.error?.data?.errorKey === `user.balanceNotEnough`
      ) {
        toast.error("Balance Not Enough");
      } else if (
        result.payload?.error?.data?.errorKey === `withdrawalRequest.pending`
      ) {
        toast.error("There is another transaction pending confirmation");
      } else {
        toast.error("There is an error. Please try again.");
      }
    } else {
      if (result.payload.data) {
        if (result.payload.data?.is_sucess) {
          dispatch(getDasboardInfo());
          dispatch(getTokenInfo());
          dispatch(getWidthrawList());
          dispatch(getBalance());
          dispatch(getFee());
          toast.success("Successfully");
        } else {
          toast.error(result.payload.data.message);
        }
      } else {
        toast.error("There is an error. Please try again.");
      }
    }
    setLoadingSub(false);
  };
  return (
    <>
      <WithdrawWapper>
        <PannerNetword bg={NewBg}>
          <div>
            <h3>Easily Withdraw</h3>
            <p>Providing the low fee of withdraw for your best experience</p>
          </div>
        </PannerNetword>
        <WithdrawConten>
          <WithdrawInputGroup>
            <WithdrawBalance>
              <img src={wdrawIcon} alt="icon" />
              <WithdrawBalanceText>
                <h6>Available</h6>
                <h3>
                  {" "}
                  {balanceWithdraw?.money_token
                    ? formatNumber(
                        (
                          Math.floor(
                            Number(balanceWithdraw?.money_token) * 100
                          ) / 100
                        )?.toFixed(2)
                      )
                    : 0}{" "}
                  <img src="./assets/ton_symbol.svg" alt="icon" />
                </h3>
                <p>
                  ~
                  {TokenInfo?.prices?.USD && balanceWithdraw?.money_token
                    ? formatNumber(
                        (
                          Number(balanceWithdraw?.money_token) *
                          TokenInfo?.prices?.USD
                        )?.toFixed(2)
                      )
                    : 0}{" "}
                </p>
              </WithdrawBalanceText>
            </WithdrawBalance>
            <WithdrawInputBox>
              <WithdrawTabs>
                <WithdrawTab
                  className={tabsActive === 1 ? "Tab_Active" : ""}
                  onClick={() => {
                    setTabsActive(1);
                  }}
                >
                  <BoxTitleRadius>
                    <h3>Withdraw</h3>
                  </BoxTitleRadius>
                </WithdrawTab>
                <WithdrawTab
                  className={tabsActive !== 1 ? "Tab_Active" : ""}
                  onClick={() => {
                    setTabsActive(2);
                  }}
                >
                  <BoxTitleRadius>
                    <h3>Transfer</h3>
                  </BoxTitleRadius>
                </WithdrawTab>
              </WithdrawTabs>
              {tabsActive === 1 ? (
                <WidthRawBox
                  amount={amount}
                  setAmount={setAmount}
                  fee={fee}
                  setFee={setFee}
                  handleWithdraw={handleWithdraw}
                  loadingSub={loadingSub}
                />
              ) : (
                <TransferBox
                  amount={amount}
                  setAmount={setAmount}
                  fee={1}
                  setFee={setFee}
                  handleWithdraw={handleWithdraw}
                  loadingSub={loadingSub}
                  setRevierAddress={setRevierAddress}
                  revierAddress={revierAddress}
                ></TransferBox>
              )}
            </WithdrawInputBox>
            {isMobile && (
              <TableWithdrawMobile>
                <BoxTitleRadius>
                  <h3>History</h3>
                </BoxTitleRadius>
                <TableWithdraw WidthrawList={WidthrawList} />
              </TableWithdrawMobile>
            )}
          </WithdrawInputGroup>
          <WithdrawHisBox>
            {!isMobile && (
              <>
                <BoxTitleRadius>
                  <h3>History</h3>
                </BoxTitleRadius>
                <TableWithdraw WidthrawList={WidthrawList} />
              </>
            )}
          </WithdrawHisBox>
        </WithdrawConten>
      </WithdrawWapper>
    </>
  );
};

export default Withdraw;

const BoxNotification = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 12px;
  justify-content: space-between;
  padding: 4px !important;
  padding-right: 12px;
  margin-bottom: 8px;
  border-radius: 34px !important;
  background: var(--Gray-100, #2d2f35);
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px !important;
    border-radius: 50%;
    width: fit-content;
    gap: 12px;
    margin-bottom: 0px;
    img {
      width: 40px;
      height: 40px;
      max-width: 100%;
      max-height: 100%;
    }
  }
  p {
    color: var(--Gray-40, #80c2ff);
    leading-trim: both;
    text-edge: cap;
    font-family: AnekLatin-Regular;
    font-weight: 500;
    font-size: 16px;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0.15px;
    width: fit-content;
    margin: 0px;
  }
  h6 {
    color: var(--Gray-Base, #c3c6cf);
    leading-trim: both;
    text-edge: cap;
    font-family: AnekLatin-Regular;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.16px;
    padding-right: 12px;
    span {
      color: var(--Blue-Base, #fff);
    }
  }
  @media screen and (max-width: 767px) {
    h6 {
      line-height: normal;
      padding-right: 0;
    }
  }
`;
