import styled from "styled-components";
import Bg from "../../assets/Netword/Bg.png";
import { BoxTitleRadius } from "../Dashboard/styled";
import { StakingSwitchTabs } from "../Staking/styled";
import { TableContainer } from "../../components/Table";

export const NetworkWapper = styled.div`
  width: 100%;
  padding: 40px 24px 40px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 1660px;
  margin: 0px auto;
  @media only screen and (max-width: 820px) {
    .withdraw_colum {
      flex-direction: column;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 25px 0 100px;
    width: auto;
    min-height: calc(100vh - 68px);
    ${StakingSwitchTabs} {
      margin-bottom: 5px;
    }
    ${TableContainer} {
      background-color: transparent;
      table {
        min-width: auto;
      }
      thead {
        display: none;
      }
      tbody {
        display: block;
        tr {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          border-radius: 8px;
          background: #1b1c20;
          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
        td {
          line-height: normal;
          padding: 10px 15px;
          &:nth-child(1) {
            width: 50%;
            & > div {
              display: flex;
              align-items: center;
              gap: 10px;
            }
          }
          &:nth-child(2) {
            order: 3;
            width: 100%;
            & > div {
              display: flex;
              align-items: center;
              justify-content: space-between;
              & > p {
                color: var(--Gray-Base, #878ea0);
                font-family: "AnekLatin-Regular";
                font-size: 16px;
              }
            }
          }
          &:nth-child(3) {
            width: 100%;
            order: 4;
            & > div {
              display: flex;
              justify-content: space-between;
              & > p {
                color: var(--Gray-Base, #878ea0);
                font-family: "AnekLatin-Regular";
                font-size: 16px;
              }
            }
          }
          &:nth-child(4) {
            width: 50%;
            & > div {
              margin-left: auto;
              padding: 10px 24px;
              width: max-content;
              border-radius: 34px;
              background: #0085ff;
            }
          }
        }
      }
    }
  }
`;
export const NetworkInner = styled.div`
  @media screen and (max-width: 767px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;
export const PannerNetword = styled.div<{ bg?: any }>`
  width: 100%;
  display: flex;
  align-items: center;
  background-image: url(${({ bg }) => (bg ? bg : Bg)});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  height: 172px;
  border-radius: 16px;
  padding: 32px;
  margin-bottom: 20px;
  div {
    display: flex;
    flex-direction: column;
    gap: 8px;
    h3 {
      font-family: "AnekLatin-Regular";
      font-size: 37px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      background: linear-gradient(90deg, #fff 51.48%, #2d2f35 171.59%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    p {
      color: var(--Light-Gray-Base, #dce3eb);
      font-family: "AnekLatin-Regular";
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px; /* 87.5% */
    }
  }
  @media screen and (max-width: 767px) {
    height: unset;
    padding: 0px;
    div {
      display: none;
      h3 {
        font-size: 32px;
      }
      p {
        line-height: 22px;
      }
    }
  }
  .Icon {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 100%;
    img {
      max-height: 120px;
      height: 100%;
    }
  }
`;

export const NetWortContent = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  @media screen and (max-width: 767px) {
    display: block;
  }
`;
export const BoxElm = styled.div`
  width: calc(50% - 8px);
  display: flex;
  flex-direction: column;
  gap: 4px;
  ${BoxTitleRadius} {
    padding: 8px 8px 8px 16px;
    h3 {
      display: flex;
      align-items: center;
      gap: 8px;
      span {
        border-radius: 50%;
        background: var(--Gray-90, #2d2f35);
        color: var(--Gray-10, #e7e8ec);
        leading-trim: both;
        text-edge: cap;
        font-family: "PlusJakartaSans";
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 200% */
        letter-spacing: 0.18px;
        padding: 2px 10px;
        /* color: #fff; */
        -webkit-background-clip: initial;
        -webkit-text-fill-color: initial;
      }
    }
  }
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const ButtonCopyLink = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 8px 16px;
  border-radius: 34px;
  background: var(--Blue-Base, #0085ff);
  color: #fff;
  border: none;
  @media screen and (max-width: 767px) {
    font-size: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
`;

export const BoxElmTable = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 16px;
  background: var(--Gray-100, #1b1c20);
  @media screen and (max-width: 767px) {
    background-color: #2d2f35;
    padding-left: 12px;
    padding-right: 12px;
  }
`;

export const BoxValueGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  @media screen and (max-width: 1280px) {
    flex-direction: column;
    gap: 16px;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    gap: 4px;
  }
`;
export const BoxValueElm = styled.div`
  display: flex;
  align-items: center;
  gap: 13px;
  align-self: stretch;
  width: calc(50% - 2px);
  span {
    width: 44px;
    min-width: 44px;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0085ff;
    border-radius: 50%;
    img {
      width: 24px;
      height: 24px;
    }
  }
  div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: stretch;
    p {
      color: var(--Gray-30, #c3c6cf);
      leading-trim: both;
      text-edge: cap;
      font-family: "AnekLatin-Regular";
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 100% */
    }
    h6 {
      color: var(--Light-Gray-10, #f8f9fb);
      leading-trim: both;
      text-edge: cap;
      font-family: "PlusJakartaSans";
      font-size: 17px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 125% */
      display: flex;
      align-items: flex-end;
      gap: 4px;
      img {
        width: 16px;
      }
    }
  }
  @media screen and (max-width: 1280px) {
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    gap: 10px;
    border-radius: 34px;
    background: rgba(85, 174, 255, 0.25);
    width: 100%;
    padding: 8px;
    & > div {
      align-self: unset;
      gap: 5px;
      p {
        font-size: 12px;
      }
    }
  }
`;
