import styled from "styled-components";
import Bg from "../../assets/Dashboard/BgBox.png";
import BgStake from "../../assets/Staking/BgStaking.png";
import { BoxValue, IconBox } from "../Dashboard/styled";
import StakedBg from "../../assets/Staking/StakedBg.png";

export const BoxTitlePage = styled.div`
  display: flex;
  h3 {
    font-family: "anek-odia-600-normal";
    color: #fff;
    font-size: 35px;
    line-height: 100%;
  }
  p {
    font-family: AnekLatin-Regular;
    font-weight: 500;
    color: var(--Gray-Base, #878ea0);
    font-size: 15px;
    line-height: 100%;
  }
  @media only screen and (min-width: 991px) {
    display: none;
  }
`;

export const StakingWapper = styled.div`
  width: 100%;
  padding: 40px 24px 40px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 1660px;
  margin: 0px auto;
  @media only screen and (max-width: 820px) {
    .withdraw_colum {
      flex-direction: column;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 25px 15px 100px;
  }
`;
export const StakingTitle = styled.div<{ typeRever?: boolean }>`
  display: flex;
  align-items: center;
  gap: 16px;
  @media only screen and (max-width: 820px) {
    flex-direction: ${({ typeRever }) =>
      typeRever ? "column-reverse" : "column"};
  }
`;
export const StakingTitlePoll = styled.div`
  width: 58%;
  background-image: url(${BgStake});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top right;
  padding: 33px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
  border-radius: 16px;
  align-self: stretch;
  h3 {
    color: var(--Light-Gray-10, #f8f9fb);
    font-family: "AnekLatin-Regular";
    font-size: 37px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    margin-bottom: 4px;
  }
  h6 {
    color: var(--Gray-50, #9ba1b0);
    font-family: "AnekLatin-Regular";
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 155.556% */
    max-width: 345px;
  }
  p {
    color: var(--Blue-30, #80c2ff);
    font-family: "AnekLatin-Regular";
    font-size: 19px;
    font-weight: 400;
    line-height: 16px; /* 88.889% */
    letter-spacing: 0.18px;
    margin-bottom: 8px;
  }
  @media only screen and (max-width: 991px) {
    h6 {
      max-width: 250px;
    }
  }
  @media only screen and (max-width: 820px) {
    width: 100%;
    h6 {
      max-width: 345px;
    }
  }
  @media only screen and (max-width: 768px) {
    background-position: center;
    padding: 0px;
    div{
      display: none;
    }
  }
`;

export const PoolAddress = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 6px 6px 6px 16px;
  border-radius: 8px;
  background: var(--Blue-70, #0059aa);
  gap: 4px;
  h6 {
    color: var(--Light-Gray-Base, #dce3eb);
    font-family: "AnekLatin-Regular";
    font-size: 21px;
    font-weight: 400;
    line-height: normal;
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    gap: 10px;
    width: 92px;
    height: 36px;
    color: #fff;
    text-align: center;
    font-family: "AnekLatin-Regular";
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    border-radius: 8px;
    background: var(--Blue-50, #2b99ff);
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    h6 {
      font-size: 15px;
    }
  }
`;

export const PoolInformation = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  width: calc(42% - 14px);
  padding: 12px;
  flex-wrap: wrap;
  margin-top: 4px;
  align-self: stretch;
  background: #2d2f35;
  border-radius: 16px;
  @media only screen and (max-width: 820px) {
    width: 100%;
  }
`;
export const InformationBox = styled.div`
  width: calc(50% - 2px);
  align-self: stretch;
  padding: 12px;
  border-radius: 16px;
  background: var(--Gray-100, #1b1c20);
  background-size: 200%;
  background-position: top left;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  gap: 13px;
  box-shadow: 0px 0px 0px 0px #5a5f6b;
  &:hover {
    background-color: #2d2f35;
    background-position: top right;
    box-shadow: 0px 4px 0px 0px #5a5f6b;
  }
  ${IconBox} {
    width: 32px;
    height: 32px;
    background-color: #0085ff;
    img {
      width: 18px;
    }
  }
  ${BoxValue} {
    p {
      font-size: 15px;
    }
    h3 {
      font-size: 19px;
      display: flex;
      align-items: center;
      gap: 4px;
      img {
        width: 16px;
      }
    }
  }
`;

export const StakeTON = styled.div`
  width: calc(42% - 14px);
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-self: stretch;

  @media only screen and (max-width: 820px) {
    width: 100%;
  }
`;
export const StakeTONConten = styled.div`
  width: 100%;
  padding: 16px;
  border-radius: 16px;
  background: var(--Gray-100, #1b1c20);
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
`;
export const InputStakeBox = styled.div`
  width: 100%;
  padding: 16px;
  border-radius: 16px;
  background: var(--Gray-90, #2d2f35);
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const BoxGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
  @media only screen and (max-width: 1280px) {
    gap: 16px;
  }
`;
export const BoxElm = styled.div`
  width: calc((100% - 12px) / 4);
  padding: 10px;
  border-radius: 34px;
  background: rgba(204, 231, 255, 0.15);
  display: flex;
  gap: 13px;
  align-items: center;
  @media only screen and (max-width: 1280px) {
    width: calc((100% - 16px) / 2);
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;
export const BoxImg = styled.div`
  width: 40px;
  aspect-ratio: 1;
  background-color: rgba(255, 255, 255, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  img {
    max-width: 100%;
  }
`;
export const BoxDetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  p {
    color: var(--Gray-40, #afb4c0);
    leading-trim: both;
    text-edge: cap;
    font-family: AnekLatin-Regular;
    font-weight: 500;
    font-size: 17px;
    font-weight: 400;
    line-height: 90%; /* 100% */
  }
  h3 {
    color: var(--Light-Gray-10, #f8f9fb);
    leading-trim: both;
    text-edge: cap;
    font-family: "PlusJakartaSans";
    font-size: 21px;
    font-weight: 500;
    line-height: 90%; /* 100% */
  }
`;
export const StakingContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 26px;
  @media only screen and (max-width: 820px) {
    flex-direction: column-reverse;
  }
`;
export const StakingContentBox = styled.div`
  width: calc(50% - 13px);
  display: flex;
  flex-direction: column;
  align-self: stretch;
  gap: 4px;
  @media only screen and (max-width: 820px) {
    width: 100%;
  }
`;
export const StakingLeftContent = styled.div`
  width: 100%;
  border-radius: 16px;
  background: var(--Gray-90, #2d2f35);
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  @media only screen and (max-width: 768px) {
    padding: 10px;
  }
`;
export const YourStakedContainer = styled.div`
  width: 58%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-self: stretch;
  @media only screen and (max-width: 820px) {
    width: 100%;
  }
`;

export const BoxInputStaking = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 24px;
  border-radius: 16px;
  border: 1px solid var(--Gray-90, #2d2f35);
  background: var(--Gray-Dark, #121315);
`;
export const BoxTitleBlance = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h6 {
    color: var(--Gray-Base, #878ea0);
    text-align: center;
    font-family: AnekLatin-Regular;
    font-weight: 500;
    font-size: 17px;
    line-height: 100%; /* 16px */
  }
`;
export const InvestWalletList = styled.ul<{ show?: any }>`
  position: absolute;
  width: 100%;
  display: flex;
  flexdirection: column;
  gap: 5px;
  bottom: -5px;
  left : -0px;
  transform: translateY(100%);
  opacity: ${({ show }) => (show ? 1 : 0)};
  height: ${({ show }) => (show ? "44px" : 0)};
  background: #fff;
  border-radius: 8px;
  transition: 0.3s linear;
  overflow: hidden;
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 16px;
    p {
      leading-trim: both;
      text-edge: cap;
      font-family: AnekLatin-Regular;
      font-weight: 500;
      font-size: 17px;
      font-weight: 400;
      line-height: 100%;
      white-space: nowrap;
      padding: 0px;
    }
  }
`;
export const BoxBoxBlance = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 28px;
  background: rgba(128, 194, 255, 0.25);
  padding: 10px 16px;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  p {
    color: var(--Blue-70, #0059aa);
    leading-trim: both;
    text-edge: cap;
    font-family: AnekLatin-Regular;
    font-weight: 500;
    font-size: 17px;
    font-weight: 400;
    line-height: 100%;
    display: flex;
    align-items: center;
    gap: 5px;
    position: relative;
    padding: 10px 12px;
    border-radius: 28px;
    background: #fff;
    span {
      width: 16px;
    }
    input {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: 1;
      height: 100%;
      width: 100%;
      cursor: pointer;
    }
  }
  h6,
  span {
    color: #fff;
    leading-trim: both;
    text-edge: cap;
    font-family: "PlusJakartaSans";
    font-size: 21px;
    font-weight: 500;
    line-height: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  span {
    color: var(--Blue-Base, #0085ff);
  }
  img {
    width: 24px;
  }
  /* ${InvestWalletList} {
    opacity: 1;
  } */
`;

export const BoxInputTyprNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  h6 {
    width: 100%;
    color: var(--Gray-Base, #878ea0);
    font-family: "AnekLatin-Regular";
    font-size: 17px;
    font-weight: 400;
    line-height: 100%;
    margin-bottom: 8px;
  }
  input {
    color: var(--Blue-10, #cce7ff);
    leading-trim: both;
    text-edge: cap;
    font-family: "PlusJakartaSans";
    font-size: 29px;
    font-weight: 500;
    line-height: 110%; /* 88.889% */
    width: calc(100% - 36px);
    background-color: transparent;
    border: none;
    outline: none;
  }
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    background: var(--Gray-100, #1b1c20);
    width: 100%;
    gap: 8px;
    padding: 16px;

    button {
      background-color: transparent;
      border: none;
      outline: none;
      padding: 0px;
      margin: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--Blue-Base, #0085ff);
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: "anek-odia-600-normal";
      font-size: 21px;
      font-weight: 600;
      line-height: 100%; /* 16px */
      padding-top: 3px;
    }
    img {
      max-height: 100%;
    }
  }
  @media only screen and (max-width: 768px) {
    input {
      font-size: 25px;
      width: calc(100% - 120px);
    }
    div {
      p {
        font-size: 15px;
      }
    }
  }
`;
export const TextUsdt = styled.p`
  color: var(--Gray-Base, #878ea0);
  leading-trim: both;
  text-edge: cap;
  font-family: "PlusJakartaSans";
  font-size: 21px;
  font-weight: 400;
  line-height: 100%;
  text-align: end;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 3px;
`;

export const BoxStaked = styled.div`
  width: 100%;
  padding: 16px 16px;
  border-radius: 16px;
  background-image: url(${StakedBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  display: flex;
  flex-direction: column;
  gap: 10px;
  p {
    color: var(--Light-Gray-Base, #dce3eb);
    font-family: "AnekLatin-Regular";
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }
  h3 {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #fff;
    font-family: "PlusJakartaSans";
    font-size: 33px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.48px;
    span {
      display: flex;
      align-items: center;
    }
    img {
      width: 32px;
    }
  }
  h6 {
    color: var(--Blue-40, #55aeff);
    font-family: "AnekLatin-Regular";
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 83.333% */
  }
`;

export const StakedTotal = styled.div``;

export const BoxNotification = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 12px;
  padding: 4px 8px 4px 4px;
  border-radius: 34px;
  background: var(--Gray-80, #444750);
  div {
    width: 40px;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--Gray-90, #1b1c20);
    border-radius: 50%;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  p {
    font-family: AnekLatin-Regular;
    color: var(--Blue-20, #aad6ff);
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 133.333% */
    letter-spacing: 0.15px;
  }
  @media only screen and (max-width: 1280px) {
    p {
      font-size: 13px;
    }
  }
`;
export const Submit = styled.button`
  padding: 16px;
  width: 100%;
  border: none;
  outline: none;
  border-radius: 33px;
  opacity: 0.5;
  background: var(--Blue-Base, #0085ff);
  color: #fff;
  leading-trim: both;
  text-edge: cap;
  font-family: AnekLatin-Regular;
  font-weight: 500;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.16px;
  transition: all 0.3s linear;
  &:hover {
    opacity: 1;
  }
  &:active {
  }
`;
export const StakingRightContent = styled.div`
  border-radius: 16px;
  background: var(--Gray-90, #2d2f35);
  padding: 49px 16px 16px 16px;
  width: 100%;
  background-image: url(${Bg});
  background-size: contain;
  background-position: top right;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: space-between;
  background-repeat: no-repeat;
  flex: 1;
  @media only screen and (max-width: 768px) {
    padding: 30px 10px 10px 10px;
  }
`;
export const StakingRightTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  h6 {
    color: var(--Gray-Base, #878ea0);
    font-family: AnekLatin-Regular;
    font-weight: 500;
    font-size: 17px;
    font-weight: 400;
    line-height: 100%;
  }
  h3 {
    color: var(--Light-Gray-10, #f8f9fb);
    font-family: "PlusJakartaSans";
    font-size: 40px;
    font-weight: 400;
    line-height: 80%; /* 80% */
    letter-spacing: 0.6px;
    span {
      color: var(--Blue-Base, #0085ff);
    }
  }
  p {
    color: var(--Blue-10, #cce7ff);
    font-family: AnekLatin-Regular;
    font-weight: 500;
    font-size: 25px;
    font-weight: 400;
    line-height: 83%; /* 83.333% */
  }
  @media only screen and (max-width: 768px) {
    h3 {
      font-size: 33px;
    }
  }
`;
export const BoxTotalStakeGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
export const BoxTotalStakeElm = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  width: calc(50% - 8px);
  border-radius: 16px;
  background: var(--Blue-50, #1a3651);
  padding: 16px 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 23px;
`;
export const BoxTotalDetail = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  p {
    color: var(--Gray-40, #afb4c0);
    font-family: AnekLatin-Regular;
    font-weight: 500;
    font-size: 17px;
    font-weight: 400;
    line-height: 100%; /* 100% */
  }
  h3 {
    color: var(--Light-Gray-10, #f8f9fb);
    font-family: "PlusJakartaSans";
    font-size: 21px;
    font-weight: 400;
    line-height: 100%; /* 100% */
    letter-spacing: 0.3px;
    display: flex;
    align-items: center;
    gap: 10px;
    img {
      width: 20px;
    }
  }
`;
export const StakingSwitchTabs = styled.div`
  border-radius: 34px;
  background: #2d2f35;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px;
  gap: 5px;
  margin-bottom: 5px;
  span {
    border-radius: 8px;
    background: var(--Gray-90, #2d2f35);
    color: var(--Gray-10, #e7e8ec);
    leading-trim: both;
    text-edge: cap;
    font-family: "PlusJakartaSans";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 200% */
    letter-spacing: 0.18px;
    padding: 2px 7px;
    margin-left: 5px;
    -webkit-background-clip: initial;
    -webkit-text-fill-color: initial;
  }
  & > div {
    width: 50%;
    font-family: "AnekLatin-Regular";
    border-radius: 34px;
    background: #0085ff;
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    padding: 15px;
  }
`;
