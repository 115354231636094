import styled from "styled-components";
import {
  ModalContent,
  WalletInfo,
  WalletInfoContent,
} from "../ModalDisConnect/styled";
import { useContext } from "react";
import { MenuContext } from "../../Provider/MenuProvider";
import { useTonAddress } from "@tonconnect/ui-react";

const ModalNoti = () => {
  const { handleCloseCustom } = useContext(MenuContext);
  const address = useTonAddress();
  return (
    <ModalLayout>
      <ModalContainer
        onClick={(e) => {
          e.stopPropagation();
          // e.preventDefault();
        }}
      >
        <WalletInfoContent>
          <ModalContent style={{ padding: "12px" }}>
            <WalletInfo style={{ padding: "16px 12px" }}>
              <p
                style={{
                  maxWidth: "100%",
                  color: "rgb(255, 255, 255)",
                  paddingRight: 20,
                  fontWeight: "600",
                }}
              >
                📢 Join the iTON IDO Directly with TON on TON Holding! 📢
              </p>
              <p style={{ maxWidth: "100%", color: "rgb(255, 255, 255)" }}>
                Great news for the TON Holding community! You can now use TON
                from TON Holding to participate in the exclusive iTON IDO
                rounds. This is a unique chance to get in early and enjoy
                exclusive benefits in iTON’s growth journey.
              </p>
              <p style={{ maxWidth: "100%", color: "rgb(255, 255, 255)" }}>
                🔹 Angel Round: Priority access for large investments in the
                earliest stage.
              </p>
              <p style={{ maxWidth: "100%", color: "rgb(255, 255, 255)" }}>
                🔹 Seed Round: Designed for strategic investors with high growth
                potential.
              </p>
              <p style={{ maxWidth: "100%", color: "rgb(255, 255, 255)" }}>
                Link Buy IDO :{" "}
                <HyperLink
                  style={{
                    maxWidth: "100%",
                    color: "rgb(255, 255, 255)",
                    fontWeight: "900",
                  }}
                  href="https://iton.co/buy-ido"
                  target="_blank"
                >
                  https://iton.co/buy-ido
                </HyperLink>
              </p>
              <p style={{ maxWidth: "100%", color: "rgb(255, 255, 255)" }}>
                With this IDO, we’re making investment easy and accessible
                directly through TON on TON Holding. Don’t miss your chance to
                be part of iTON and help shape the future of DeFi on TON
                Blockchain! 🚀
              </p>
              <BtnClose onClick={handleCloseCustom}></BtnClose>
            </WalletInfo>
          </ModalContent>
        </WalletInfoContent>
      </ModalContainer>
    </ModalLayout>
  );
};
export default ModalNoti;
const addressKey: any = {
  "UQAEmAR293imhbXNfjCMr34nJhBw61VKSpP0h4O7c-IfdhMB": {
    current: 21.85,
    total: 65.55,
    revert: 43.7,
  },
  "UQBVKbRuzlVZx44i0PdZ1OvE-HqBtcZRK9MFhk74GvdOHuhz": {
    current: 21.85,
    total: 65.55,
    revert: 43.7,
  },
  "UQBVPGyHlXh0Zn4r1ANXa_KSZFIVI1Cs6Gw-2jnvaRyExcFs": {
    current: 11.4,
    total: 45.6,
    revert: 34.2,
  },
  "UQAJB7eH4PgaJdQnnjXCgQ1Dg8ILBdmD6jh6ark_-YEUq4me": {
    current: 4.37,
    total: 26.22,
    revert: 21.85,
  },
};

const ModalLayout = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
const ModalContainer = styled.div`
  border-radius: 20px;
  width: 100%;
  max-width: 420px;
`;
const BtnClose = styled.button`
  width: 24px;
  height: 24px;
  background-color: #e0e0e0;
  border-radius: 4px;
  border: none;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  &::after,
  &::before {
    content: "";
    width: 16px;
    height: 2px;
    background-color: #000;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) rotateZ(45deg);
  }
  &::before {
    transform: translateX(-50%) translateY(-50%) rotateZ(-45deg);
  }
`;
const HyperLink = styled.a`
  /* background: linear-gradient(to right, #fff 0% , #2b99ff 25% , #fff 50% , #2b99ff 75% , #fff 100%); */
  background: linear-gradient(to right, #fff 0% , #2b99ff 50% , #fff 100%);
  background-size: 600%;
  animation: textLine 3s linear infinite;
  -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  @keyframes textLine {
    0%{
      background-position: left center;
    }100%{
      background-position: right center;
    }
  }
`